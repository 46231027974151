/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 259px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 74.6987951807229%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIAA//aAAwDAQACEAMQAAABzveY0YYj/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECESID/9oACAEBAAEFAnXXneGSy1OPDk//xAAWEQADAAAAAAAAAAAAAAAAAAABEBL/2gAIAQMBAT8Bkr//xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8BoX//xAAbEAABBAMAAAAAAAAAAAAAAAAAAQJBYRExMv/aAAgBAQAGPwJ14HIsEmh1nJ//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMUFR/9oACAEBAAE/IULbOjOmhjPIouxKji21+xJtexZnzP/aAAwDAQACAAMAAAAQU+//xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8QENJ//8QAFhEAAwAAAAAAAAAAAAAAAAAAARAx/9oACAECAQE/EDJf/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARMSFBUWH/2gAIAQEAAT8QcGQAaPUSuYW9l9/Y1sLwyIDJJtMGJwNaK08ip787P//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"sabotage origin\"\n        title=\"sabotage origin\"\n        src=\"/static/7d65678f3aebd088734c956b332fe15e/73b39/sabotage.jpg\"\n        srcset=\"/static/7d65678f3aebd088734c956b332fe15e/4d5fb/sabotage.jpg 166w,\n/static/7d65678f3aebd088734c956b332fe15e/73b39/sabotage.jpg 259w\"\n        sizes=\"(max-width: 259px) 100vw, 259px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "In the 1400’s Netherlands textile mills were undergoing some changes. "), "\n", React.createElement(_components.p, null, "Factories were starting to mechanize certain elements of textile looms. "), "\n", React.createElement(_components.p, null, "Workers in these factories feared that the factories were going to become so dependent on machines that the security of their jobs was in the balance. "), "\n", React.createElement(_components.p, null, "Workers in the Netherlands at the time wore a traditional wooden shoe call a “sabot” and consequently the gears of the textile looms were constructed of wood. "), "\n", React.createElement(_components.p, null, "In order to prevent machines from essentially taking over their jobs the workers threw their sabots into the wooden gears of the textile looms to break them-  hence was born the term “sabotage”."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
